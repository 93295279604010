import browserEnv from '~/libs/env/browserEnv'
import awsExportsDev from './aws-exports-dev.js'
import awsExportsStg from './aws-exports-stg.js'
import awsExportsProd from './aws-exports.js'

const getAwsExports = () => {
  switch (browserEnv.NEXT_PUBLIC_COGNITO_ENV) {
    case 'dev':
      return awsExportsDev
    case 'stg':
      return awsExportsStg
    default:
      return awsExportsProd
  }
}

export const awsExports = getAwsExports()
